import { useEffect, useRef, useState, Fragment } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { getSearchedCoin } from "../../server/api/methods";
import Link from "next/link";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import Image from "next/image";

import { Combobox, Dialog, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import {
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faStar } from "@fortawesome/free-solid-svg-icons";
import router from "next/router";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  inputClassName?: string;
  withModal?: boolean;
  withInput?: boolean;
};
export default function SearchCoin({ inputClassName, withModal, withInput }: Props) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: [searchTerm],
    queryFn: () => getSearchedCoin(searchTerm),
  });

  const [search, setSearch] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 250);
  const initialMaxResults = 6;
  const [maxResults, setMaxResults] = useState<number>(initialMaxResults);
  const mySearchRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (data?.length > 0) {
      setIsVisible(true);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (mySearchRef.current && !mySearchRef.current.contains(event.target as Node)) {
        // Handle the click outside event
        console.log("Clicked outside of the div");
        setIsVisible(false);
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mySearchRef]);

  useEffect(() => {
    setSearchTerm(search);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (open || withInput) {
      return;
    }

    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "k" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen(true);
      }
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [open, setOpen]);

  return (
    <>
      {withInput && (
        <>
          <div className="relative mt-1 flex items-center">
            <input
              id="projectName"
              autoFocus={true}
              type="text"
              placeholder="Quick search by project name"
              className={
                inputClassName
                  ? inputClassName
                  : `block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-400 dark:focus:ring-offset-gray-900`
              }
              onFocus={() => setIsVisible(true)}
              onChange={handleChange}
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400 dark:border-gray-700 dark:text-gray-300">
                ⌘K
              </kbd>
            </div>
          </div>
        </>
      )}
      {withModal && (
        <div
          className="inline-flex cursor-pointer items-center rounded-lg p-2 sm:p-2.5 text-sm text-gray-500 hover:bg-slate-100 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-slate-700 dark:hover:text-gray-300 dark:focus:ring-gray-700"
          onClick={() => setOpen(true)}
        >
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      )}
      {withInput && data?.length > 0 && isVisible && (
        <div
          className="absolute z-10 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:bg-gray-800 sm:block"
          ref={mySearchRef}
        >
          <div className="space-y-3 p-3">
            {data.slice(0, maxResults).map((coin: any, index: number) => {
              return (
                <Link
                  href={`/coin/review/${coin.coinId}`}
                  className="flex w-full cursor-pointer select-none rounded-xl p-3 hover:bg-gray-100 dark:hover:bg-gray-700"
                  key={index}
                >
                  <div
                    className={classNames(
                      "flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-slate-100 dark:bg-gray-700 dark:text-gray-300",
                    )}
                  >
                    {/* Coin */}
                    {!coin?.small && <FontAwesomeIcon icon={faCoins} />}
                    {coin?.small && <Image src={"/img/logos/" + coin?.small || ""} alt={coin?.coinName || ""} width={25} height={25} />}
                  </div>
                  <div className="ml-4 flex-auto">
                    <p className={classNames("text-sm font-medium", "text-gray-700 dark:text-gray-300")}>
                      {coin.name} ${coin.symbol.toUpperCase()}
                      {coin?.countReviews > 0 && (
                        <div className="ml-2 inline-block text-xs text-gray-400 dark:text-gray-500">({coin?.countReviews} reviews)</div>
                      )}
                    </p>
                    <p className={classNames("pointer-events-none text-sm", "text-gray-500 dark:text-gray-400")}>
                      <Box
                        sx={{
                          width: 200,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Rating
                          size="large"
                          name="hover-feedback"
                          value={coin?.rating || 0}
                          precision={1}
                          emptyIcon={
                            // <StarIcon
                            //   style={{ opacity: 0.5 }}
                            //   fontSize="inherit"
                            //   className="text-yellow-400 dark:text-yellow-600"
                            // />
                            <FontAwesomeIcon className="h-4 w-4 text-yellow-400 opacity-25 dark:text-yellow-600" icon={faStar} />
                          }
                          icon={
                            <FontAwesomeIcon
                              className={classNames(
                                `h-4 w-4`,
                                coin?.rating > 0 && coin?.rating < 2 && "text-red-500",
                                coin?.rating > 2 && coin?.rating < 4 && "text-yellow-300",
                                coin?.rating > 4 && "text-emerald-300",
                              )}
                              icon={faStar}
                            />
                          }
                        />
                      </Box>
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>

          {/* {data?.length > initialMaxResults && (
            <div className="py-1">
              <button
                className="block px-4 py-2 text-sm text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                onClick={() => setMaxResults(maxResults === initialMaxResults ? data?.length : initialMaxResults)}
              >
                {maxResults > initialMaxResults ? "show less" : "show more"}
              </button>
            </div>
          )} */}
        </div>
      )}

      <Transition.Root show={open} as={Fragment} afterLeave={() => setSearch("")}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto p-4 pt-24 sm:p-6 md:p-20" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity dark:bg-gray-900 dark:bg-opacity-50" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Combobox
              as="div"
              className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:divide-gray-700 dark:bg-gray-800 dark:ring-gray-700"
            >
              <div className="relative">
                <SearchIcon
                  className="pointer-events-none absolute left-4 top-5 h-5 w-5 text-gray-400 dark:text-gray-300"
                  aria-hidden="true"
                />
                <Combobox.Input
                  className="h-16 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 dark:bg-gray-800 dark:text-gray-300 dark:placeholder-gray-500 dark:focus:ring-0 sm:text-sm"
                  placeholder="Search by project name."
                  autoFocus={true}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </div>

              {data?.length > 0 && isVisible && (
                <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                  {/* On enter go to link */}
                  {data.map((coin: any) => (
                    <Combobox.Option
                      key={"coin" + coin.coinId}
                      value={coin}
                      onKeyPress={(event: any) => {
                        if (event.key === "Enter") {
                          setOpen(false);
                          router.push(`/coin/review/${coin.coinId}`);
                        }
                      }}
                      className={({ active }) =>
                        classNames("flex cursor-pointer select-none rounded-xl p-3", active && "bg-gray-100 dark:bg-gray-700")
                      }
                    >
                      {({ active }) => (
                        <>
                          <Link href={`/coin/review/${coin.coinId}`} className="flex w-full" onClick={() => setOpen(false)}>
                            <div
                              className={classNames(
                                "flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-slate-100 dark:bg-gray-700",
                              )}
                            >
                              {/* Coin */}
                              {!coin?.small && <FontAwesomeIcon icon={faCoins} />}
                              {coin?.small && (
                                <Image src={"/img/logos/" + coin?.small || ""} alt={coin?.coinName || ""} width={25} height={25} />
                              )}
                            </div>
                            <div className="ml-4 flex-auto">
                              <p
                                className={classNames(
                                  "text-sm font-medium",
                                  active ? "text-gray-900 dark:text-gray-300" : "text-gray-700 dark:text-gray-300",
                                )}
                              >
                                {coin.name} ${coin.symbol.toUpperCase()}
                                {coin?.countReviews > 0 && (
                                  <div className="ml-2 inline-block text-xs text-gray-400 dark:text-gray-500">
                                    ({coin?.countReviews} reviews)
                                  </div>
                                )}
                              </p>
                              <p
                                className={classNames(
                                  "pointer-events-none text-sm",
                                  active ? "text-gray-700 dark:text-gray-400" : "text-gray-500 dark:text-gray-400",
                                )}
                              >
                                <Box
                                  sx={{
                                    width: 200,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Rating
                                    size="large"
                                    name="hover-feedback"
                                    value={coin?.rating || 0}
                                    precision={1}
                                    emptyIcon={
                                      // <StarIcon
                                      //   style={{ opacity: 0.5 }}
                                      //   fontSize="inherit"
                                      //   className="text-yellow-400 dark:text-yellow-600"
                                      // />
                                      <FontAwesomeIcon className="h-4 w-4 text-yellow-400 opacity-25 dark:text-yellow-600" icon={faStar} />
                                    }
                                    icon={
                                      <FontAwesomeIcon
                                        className={classNames(
                                          `h-4 w-4`,
                                          coin?.rating > 0 && coin?.rating < 2 && "text-red-500",
                                          coin?.rating > 2 && coin?.rating < 4 && "text-yellow-300",
                                          coin?.rating > 4 && "text-emerald-300",
                                        )}
                                        icon={faStar}
                                      />
                                    }
                                  />
                                </Box>
                              </p>
                            </div>
                          </Link>
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}

              {search !== "" && data?.length === 0 && search.length > 3 && (
                <div className="px-6 py-14 text-center text-sm sm:px-14">
                  <ExclamationCircleIcon type="outline" name="exclamation-circle" className="mx-auto h-6 w-6 text-gray-400 dark:text-gray-500" />
                  <p className="mt-4 font-semibold text-gray-900 dark:text-gray-300">No results found</p>
                  <p className="mt-2 text-gray-500 dark:text-gray-400">No project found for this search term. Please try again.</p>
                </div>
              )}

              {search !== "" && data?.length === 0 && search.length <= 3 && (
                <div className="px-6 py-14 text-center text-sm sm:px-14">
                  <ExclamationCircleIcon type="outline" name="exclamation-circle" className="mx-auto h-6 w-6 text-red-400 dark:text-red-500" />
                  <p className="mt-4 font-semibold text-red-900 dark:text-red-300">Search term too short</p>
                  <p className="mt-2 text-red-500 dark:text-red-400">Please enter at least 4 characters.</p>
                </div>
              )}
            </Combobox>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
}
