const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http';
const domainPort = process.env.NODE_ENV === 'production' ? 'api.web3trust.app' : `localhost:3333`;

export const getLatestReviewedCoinsByChainId = (chainId: number | undefined) => {
    return fetch(`${protocol}://${domainPort}/coins/lastReviewedCoinsByChainId`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            chainId: chainId,
        }),
    })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(error => console.log('error', error));
}

export const getCountReviewsByCoinNameIdAndChainId = (coinId: number | undefined, chainId: number | undefined) => {
    if (coinId === null) {
        return 0;
    }
    if (chainId === undefined) {
        return 0;
    }
    return fetch(`${protocol}://${domainPort}/coins/countReviewsByCoinAndChain/${coinId}/${chainId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(error => console.log('error', error));
}


export const getLatestReviews = (limit: number) => {
    return fetch(`${protocol}://${domainPort}/coins/latestReviews/${limit}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(error => console.log('error', error));
}

export const getLatestReviewsByCoinId = async (coinId: number | undefined, limit: number) => {
    try {
        if (coinId === undefined) return Promise.resolve([]);
        const response = await fetch(`${protocol}://${domainPort}/coins/${coinId}/latestReviews/${limit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const getRecentlyReviewedCoins = async (categoryId: string | undefined, limit: number) => {
    try {
        if (categoryId === undefined) return Promise.resolve([]);
        const response = await fetch(`${protocol}://${domainPort}/coins/recentlyReviewedCoins/${categoryId}/${limit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}



export const getSearchedCoin = (searchTerm: string) => {
    if (searchTerm.length < 3) return Promise.resolve([])
    const urlencoded = new URLSearchParams();
    urlencoded.append("searchParam", searchTerm);

    return fetch(`${protocol}://${domainPort}/coins/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: urlencoded,
        redirect: 'follow'
    })
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
}

export const getCoinByCoinNameId = (coinNameId: string) => {
    if (coinNameId.length <= 1) return Promise.resolve([]);
    return fetch(`${protocol}://${domainPort}/coin/${coinNameId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
}

export const getHolders = async (influencer: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holders/distinct/${influencer}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const getHoldersV2 = async (influencer: string, holdings: string[], ageOfWallet: number | null, totalTransactions: number | null): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holdersV2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                influencer: influencer,
                holdings: holdings,
                ageOfWallet: ageOfWallet,
                totalTransactions: totalTransactions
            })
        });

        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const getInfluencers = async (): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holdersV2/influencers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const getHoldings = async (influencer: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holdersV2/holdings/${influencer}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const getWalletStatistics = async (walletAddress: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holdersV2/walletStatistics/${walletAddress}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}





export const getBlockNumber = async (date: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holders/blockByDate/${date}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const addHoldersForInfluencer = async (influencerName: string, tokenContract: string, blockNumber: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holders/add`, {
            method: 'POST',
            body: JSON.stringify({
                influencer: influencerName,
                tokenContract: tokenContract,
                block: blockNumber
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const removedHoldersForInfluencer = async (influencerName: string, tokenContract: string, blockNumber: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holders/remove`, {
            method: 'POST',
            body: JSON.stringify({
                influencer: influencerName,
                tokenContract: tokenContract,
                block: blockNumber
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const holderAction = async (address: string, type: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/holders/${address}/${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const twitterAction = async (followingUsername: string, type: string): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/twitter/${followingUsername}/${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}

export const getTwitterFollowings = async (): Promise<string[] | any> => {
    try {
        const response = await fetch(`${protocol}://${domainPort}/twitter/followings`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}




export const addReviewToMysql = (coinId: number, coinNameId: string, valueRating: number | null, txHash: string, chainId: number | undefined, reviewerAddress: `0x${string}` | undefined, cid: string) => {
    fetch(`${protocol}://${domainPort}/coins/${coinNameId}/reviews`, {
        method: 'PUT',
        headers: {

            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            coinId: coinId,
            coinNameId: coinNameId,
            valueRating: valueRating,
            txHash: txHash,
            chainId: chainId,
            reviewerAddress: reviewerAddress,
            reviewHash: cid,
        }),
    })
        .then(response => {
            console.log(response);
            response.json()
        })
        .then(data => {
            // console.log('Success:', data);
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}



export const getTopCategoriesAndCoins = (offsetCategories: number, limitCategories: number, limitCoinsPerCategory: number) => {
    return fetch(`${protocol}://${domainPort}/coins/topCategoriesAndCoins/${offsetCategories}/${limitCategories}/${limitCoinsPerCategory}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(error => console.log('error', error));
}

export const addFinalRatingToMysql = (coinNameId: string, rating: string | number, chainId: number | undefined) => {
    fetch(`${protocol}://${domainPort}/coins/${coinNameId}/ratings`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            rating: rating,
            chainId: chainId
        }),
    })
        .then(response => response.json())
        .then(data => {
            // console.log('Success:', data);
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

export const getFinalRating = (coinId: number | undefined) => {
    if (!coinId) return Promise.resolve({ rating: null });
    return fetch(`${protocol}://${domainPort}/coins/${coinId}/ratings`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

export const getGoinsFromCategory = (categoryId: string, coinName: string, itemOffset: number, itemsPerPage: number, sort: string, rating: number | null) => {
    return fetch(`${protocol}://${domainPort}/coins/coinsFromCategory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            categoryId: categoryId,
            coinName: coinName,
            itemOffset: itemOffset,
            itemsPerPage: itemsPerPage,
            sort: sort,
            rating: rating
        }),
    })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(error => console.log('error', error));
}

export const getSymbolTradingView = (symbol: string) => {
    return fetch(`${protocol}://${domainPort}/tradingView/${symbol}`)
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
}

export const getMarketData = async (coinNameId: string) => {
    if (!coinNameId) return Promise.resolve([]);
    const response = await fetch(`${protocol}://${domainPort}/coins/market/${coinNameId}`);
    const result_1 = await response.json();
    return result_1;
}


export const getStats = () => {
    return fetch(`${protocol}://${domainPort}/coins/stats`)
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
}

//move to post
export const getReviews = async (coinNameId: number, offset: number, limit: number, sort: string, ratings: string[]) => {
    if (!coinNameId) return Promise.resolve([]);

    return fetch(`${protocol}://${domainPort}/coins/${coinNameId}/reviews/${offset}/${limit}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sort: sort,
                ratings: ratings
            }),
        })
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            return result;
        })
}
