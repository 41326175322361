import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import dynamic from "next/dynamic";
import { ConnectWallet } from "./general/ConnectWallet";
import SearchCoin from "./general/SearchCoin";
const ThemeSwitcher = dynamic(() => import("./themeSwitcher"), {
  ssr: false,
});

export default function Navbar() {
  return (
    <Disclosure
      as="nav"
      className={`shadow} sticky top-0 z-50 bg-white dark:bg-slate-800 dark:bg-slate-900/95 dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-slate-900/75`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <Link href="/">
                    <p className="text-2xl font-bold text-gray-900 dark:text-gray-300">
                      {/* <span className="text-sky-400">Trust</span> */}
                      <span className="relative whitespace-nowrap ">
                        <svg
                          aria-hidden="true"
                          viewBox="0 0 418 42"
                          className="absolute left-0 top-2/3 h-[0.58em] w-full fill-sky-500/40"
                          preserveAspectRatio="none"
                        >
                          <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                        </svg>
                        <span className="relative">
                          Web3<span className="text-sky-600">Trust</span>
                        </span>
                      </span>
                    </p>
                  </Link>
                </div>
                <div className="hidden lg:ml-10 lg:flex lg:space-x-8">
                  <Link
                    href="/categories"
                    className="inline-flex items-center border-b-2 border-sky-500 px-1 pt-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Categories
                  </Link>
                </div>
              </div>
              {/* <div className="flex-1 hidden sm:flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
                                <div className="max-w-lg w-full lg:max-w-xs relative">
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative hidden sm:block">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <SearchIcon className="h-5 w-5 text-gray-400 dark:text-gray-300" aria-hidden="true" />
                                        </div>
                                        <SearchCoin inputClassName="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm" />
                                    </div>


                                </div>
                            </div> */}

              <div className="flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="hidden items-center justify-center rounded-md p-2 text-gray-400 hover:bg-slate-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500 dark:text-gray-300 dark:hover:bg-slate-700 dark:hover:text-gray-200 sm:inline-flex">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center gap-2 lg:ml-4 lg:gap-3">
                <ConnectWallet />
                <span className="flex items-center gap-1">
                  <SearchCoin
                    withModal={true}
                    inputClassName="hidden sm:block w-full pl-10 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  />
                  <ThemeSwitcher />
                </span>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="border-t border-gray-200 pb-3 pt-4 dark:border-gray-700">
              <div className="mt-3 flex flex-col items-center justify-center gap-3 space-y-1">
                <ConnectWallet />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
